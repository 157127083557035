import React from 'react';



export default function EditMovie ({movie,onChangeMovie}){


    return (
       <>
            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                    Titre du film
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <input autoComplete="off" onChange={(e)=>onChangeMovie(e)} value={movie.titre} type="text" id="titre" placeholder="Titre" className=" w-full my-2 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" />
                </div>
            </div>
            <div className="flex flew-row">
                <div className="w-full">
                    <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                        Date de sortie
                    </label>
                    <div className="mt-1 ">
                        <input autoComplete="off" onChange={(e)=>onChangeMovie(e)} type="text" placeholder="YYYY-MM-DD " id="date" value={movie.date}  className=" w-full my-2 mr-2 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" />
                    </div>
                </div>
                <div  className="w-full">
                    <label htmlFor="email" className="ml-2 block text-sm font-medium leading-5 text-gray-700">
                        Date vu
                    </label>
                    <div className="mt-1 ml-2 ">
                        <input autoComplete="off" onChange={(e)=>onChangeMovie(e)} type="text" placeholder="YYYY-MM-DD " id="vu" value={movie.vu } className=" w-full my-2 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" />
                    </div>
                </div>        
            </div>
                    
                   
       </>
      

    )
}