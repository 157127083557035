import React,{useEffect, useState} from 'react';
import { getToken} from '../../functions/Authentication';
import moment from 'moment'


export default function Home (props){

    const [search,setSearch] = useState("")

    const fetchUsers = () =>{
        fetch(process.env.REACT_APP_API_URL+'/users', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                authorization:getToken()
            },
        })
        .then((response) => {
            if (!response.ok) {
                response.json().then((value) => {
                    console.log(value.message)
                });
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then((json) => {
            console.log(json)
        })
        .catch((error) => {
            console.log(error)
        });
    }

    const evenements=[
        {name:"Fin de l'annee",date:"12-31"},
        {name:"Noel",date:"12-25"},
        {name:"Anniversaire Valentin",date:"05-11"} ,
        {name:"Anniversaire Oceane",date:"06-16"} ,
        {name:"Anniversaire Morgane",date:"08/17"} ,
        {name:"Anniversaire Papa",date:"10-14"} ,
        {name:"Anniversaire Maman",date:"09-25"} ,
        {name:"Fin confinement",date:"12-01"} ,
    ]

    return (
        <>
 
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4 flex flex-col">
            <div className="flex flex-row items-center justify-center mb-3 w-full lg:w-1/3">
                <input onChange={(e)=>setSearch(e.target.value)} className=" w-full  bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" type="text" placeholder="Rechercher"/>
            </div>
        {evenements.map((event)=>{
            if(event.name.toLowerCase().search(search.toLowerCase()) === -1 ){
                return null
            }
            return(
                <ProgressBar pourcentage={event.percent} nom={event.name} date={event.date} />
            )
        })}

        </div>
            
        </>
            

    )
}

function ProgressBar ({nom,date}){

    const [today,setToday] = useState( moment().format("MM-DD"))
    const [years,setYears] = useState( moment().format("YYYY"))

    function getPourcentage(days){
        let res =parseInt(days)*100/365

        let t = 100-res

        //t = Math.trunc(t)

        return t.toString()+"%"
    }

    function countdownDate(date){

        if(date == ""){
            return ""
        }

        let isBefore = moment(date).isBefore(today);

        if(!isBefore){
            date = years+"-"+date
        }else{
            date = (parseInt(years)+1)+"-"+date
        }

        var a = moment(today, 'MM-DD');
        var b = moment(date, 'YYYY-MM-DD');
        var days = b.diff(a, 'days');

        return days
    }

    //console.log(getPourcentage(countdownDate(date)))

    return (
        <>
        <div className="mt-3"><b>{nom}</b> : {countdownDate(date)} jours restants</div>
        <div class="flex mt-2 h-3 relative w-full rounded-full overflow-hidden">
            <div class="w-full h-full bg-gray-200 absolute"></div>
            <div class="h-full bg-red-500 absolute" style={{width:getPourcentage(countdownDate(date))}}></div>
        </div>

        </>
    )
}