import React, { Component } from 'react';
import lodash from 'lodash'
import moment from 'moment'
import {formatDate,totalJours} from '../../../functions/utils'

export default function CardEssence ({id,dateDebut,dateFin,prix,distance,editEssence}){

    return (
        <div className="col-span-1 bg-white rounded-lg shadow ">
            <div onClick={()=>editEssence(id)} className="flex justify-end text-sm mr-3 mt-2 ">
                <svg  className="h-6 w-6 text-gray-400 cursor-pointer"  viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg>
            </div>
            <div className="w-full flex-col items-center px-4 -mt-5 pb-2 ">
                <div className="flex-1 truncate">
                    <div className="flex justify-center items-center">
                        <svg  viewBox="0 0 20 20" fill="#cbd5e0" className="calendar w-6 h-6">
                            <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path>
                        </svg>
                        <h3 className="text-gray-900 text-sm leading-5 font-medium truncate ml-3">Du {formatDate(dateDebut)} {dateFin == "" ? "à aujourd'hui" : "au " + formatDate(dateFin)}</h3>
                    </div>
                </div>
                <div className="flex justify-center font-medium text-sm mt-1">
                    ( {totalJours(dateDebut,dateFin)} jours )
                </div>
            </div>
            <div className="border-t border-gray-200">
                <div className="-mt-px flex">
                    <div className="w-0 flex-1 flex border-r border-gray-200">
                        <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg transition ease-in-out duration-150">
                            <svg className="h-6 w-6 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span className="ml-3">{prix} €</span>
                        </div>
                    </div>
                    <div className="-ml-px w-0 flex-1 flex">
                        <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg transition ease-in-out duration-150">
                            <svg className="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" /></svg>
                            <span className="ml-3">
                                {distance == "" ? "En cours" : distance + " kms"} 
                            </span>
                        </div>
                    </div>      
                </div>
            </div>
        </div>
    )
}