import React, { useState,useEffect } from 'react';
import moment from 'moment'
import lodash from 'lodash'

import Film from './Film'
import EditMovie from './EditMovie'
import { getUserIDAuth,getToken } from '../../../functions/Authentication';
import {isValidDate,trierDateCroissant,trierDateDecroissant,scrollTop} from '../../../functions/utils'


export default function List (props){

    const [search,setSearch] = useState("")
    const [films,setFilms] = useState([])
    const [created,setCreated] = useState(false)
    const [edited,setEdited] = useState(false)
    const [editMovie,setEditMovie] = useState(
            {
                titre:"",
                date:"",
                vu:""
            }
        )
    const [error,setError] = useState("")

    useEffect(() => {
        fetchFilms()
    }, []);


    function fetchFilms(){
        fetch(process.env.REACT_APP_API_URL+"/film/getAll",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                authorization:getToken()
            },
            body:JSON.stringify({
                user_id:getUserIDAuth()
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.err){
                setError(json.err)
            }else{
                console.log(json)
                setFilms(json.films)
            }
           
        }).catch((error)=>{
            console.log(error)
        })
    }

    function onChangeSearch(e){
        setSearch(e.target.value)
    }

    function openCreateMovie(){
        setCreated(true)
    }

    function addMovie(){
        if(editMovie.titre == "" || isValidDate(editMovie.date)==false){
            setError("Des champs ont ma été remplies")
        }else{
            setError("")
            fetch(process.env.REACT_APP_API_URL+"/film/add",{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    authorization:getToken()
                },
                body:JSON.stringify({editMovie,user_id:getUserIDAuth()})
            })
            .then((response)=>{
                return response.json()
            })
            .then((json)=>{
                setEditMovie(
                    {
                        titre:"",
                        date:"",
                        vu:""
                    }
                )
                setCreated(false)
                fetchFilms()
                
            }).catch((error)=>{
                console.log(error)
            })
        }
    }

    function updateMovie(){
        fetch(process.env.REACT_APP_API_URL+"/film/update/"+editMovie.id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                authorization:getToken()
              },
            body:JSON.stringify(editMovie)
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(!json.err){
                setEditMovie(
                    {
                        titre:"",
                        date:"",
                        vu:""
                    }
                )
                setEdited(false)
                fetchFilms()
            }else{
                setError(json.msg)
            }
        }).catch((error)=>{
            console.log(error)
            setError(error)
        })
    }

    function deleteMovie(){
        fetch(process.env.REACT_APP_API_URL+"/film/delete/"+editMovie.id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                authorization:getToken()
              },
            body:JSON.stringify(editMovie)
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(!json.err){
                setEditMovie(
                    {
                        titre:"",
                        date:"",
                        vu:""
                    }
                )
                setEdited(false)
                fetchFilms()
            }else{
                setError(json.msg)
            }
        }).catch((error)=>{
            console.log(error)
            setError(error)
        })
    }

    function goBack(){
        setCreated(false)
        setEdited(false)
        setEditMovie({
            titre:"",
            date:"",
            vu:""
        })
    }

    function onChangeMovie(e){
        setEditMovie({...editMovie,[e.target.id]:e.target.value}) 
    }

    function openEditMovie(id){
        let film
        lodash.forEach(films,(f)=>{
            if ( f.id == id){
                film = f
            }
        })
        setEdited(true)
        setEditMovie(film)
    }

    if(created){
        var y=window.scrollY;
        window.onscroll=function(){window.scrollTo(0, y);};
    }else{
        window.onscroll=function(){};
    }

    return(
        <>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">
            <div className="flex flex-col lg:flex-row">
                <div className="flex flex-row items-center justify-center mb-3 w-full lg:w-1/3">
                    <button onClick={()=>openCreateMovie()} className="w-1/2 bg-primary text-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" >
                        Ajouter un film
                    </button>
                </div>
                <div className="flex flex-row items-center justify-center mb-3 w-full lg:w-2/3">
                    <input onChange={(e)=>onChangeSearch(e)} className=" w-full  bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" type="text" placeholder="Rechercher"/>
                </div>
            </div>


            {created ? 
            <div className="fixed z-10 inset-0 overflow-y-auto">
                
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>


                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    
                    {error ? 
                        <div class="rounded-md bg-red-200 p-4 mb-3">
                            <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>
                            </div>
                                <div class="ml-3">
                                    <h3 class="text-sm leading-5 font-medium text-red-800">
                                        {error}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    : null}

                        <EditMovie movie={editMovie} onChangeMovie={onChangeMovie}  />

                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                            <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                            <button onClick={()=>addMovie()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-700 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Ajouter le film
                            </button>
                            </span>
                            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                            <button onClick={()=>goBack()} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Retour
                            </button>
                            </span>
                        </div>
                    </div>
                </div>
                </div>
            : null}

            {edited ? <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                    <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>


                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    
                    {error ? 
                        <div class="rounded-md bg-red-200 p-4 mb-3">
                            <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>
                            </div>
                                <div class="ml-3">
                                    <h3 class="text-sm leading-5 font-medium text-red-800">
                                        {error}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    : null}

                    <EditMovie movie={editMovie} onChangeMovie={onChangeMovie} />

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
                        
                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
                            <button onClick={()=>updateMovie()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-700 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                            Modifier le film
                            </button>
                        </span>

                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 ">
                            <button onClick={()=>deleteMovie()} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-red-700 text-base leading-6 font-medium text-white shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Supprimer
                            </button>
                        </span>

                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 ">
                            <button onClick={()=>goBack()} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Retour
                            </button>
                        </span>

                        
                        
                        
                    </div>
                    </div>
                </div>
                </div>
            : null}
            
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">

                {trierDateDecroissant(films).map((f,i)=>{
                    if(f.titre.toLowerCase().search(search.toLowerCase()) === -1 ){
                        return null
                    }
                    return <Film key={i} titre={f.titre} id={f.id} date={f.date} vu={f.vu} editMovie={openEditMovie}/>
                })}

                


            </ul>
            <div id="buttonToTop" onClick={scrollTop} className="bg-gray-400 p-3 rounded-full fixed bottom-0 right-0 mb-6 mr-6 md:mb-8 md:mr-8 lg:mb-12 lg:mr-12 cursor-pointer hover:bg-gray-300">
                    <svg className="h-6 w-6 text-gray-700"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <polyline points="17 11 12 6 7 11" />  <polyline points="17 18 12 13 7 18" /></svg>
                </div>
        </div>
        </>
    )
}





