import React,{useState,useEffect} from 'react'
import {movies} from '../datas/datas'
import moment from 'moment'
import lodash from 'lodash'
import { getUserIDAuth,getToken} from '../../functions/Authentication';
import {Spring} from 'react-spring/renderprops'


export default function Dashboard (props) {

    
    const [month,setMonth] = useState(moment().month())
    const [year,setYears] = useState(moment().year())
    const [films,setFilms] = useState([])
    const [essences,setEssences] = useState([])
    const [errorFilm,setErrorFilm] = useState("")
    const [errorEssence,setErrorEssence] = useState("")
    const [error,setError] = useState("")

    useEffect(() => {
        fetchFilms()
        fetchEssences()

    }, []);

    function fetchEssences(){
        fetch(process.env.REACT_APP_API_URL+"/essence/getAll",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                authorization:getToken()
            },
            body:JSON.stringify({
                user_id:getUserIDAuth()
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.err){
                setError(json.err)
            }else{
                console.log(json)
                setEssences(json.essences)
            }
            
        }).catch((error)=>{
            console.log(error)
        })
    }

    function fetchFilms(){
        fetch(process.env.REACT_APP_API_URL+"/film/getAll",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                authorization:getToken()
            },
            body:JSON.stringify({
                user_id:getUserIDAuth()
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.err){
                setError(json.err)
            }else{
                console.log(json)
                setFilms(json.films)
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    function getMonthlyMovie(month){
        let tab = []
        lodash.forEach(films,(f)=>{
            let date = f.date.split("-")
            if(date[1] == (month+1) && date[0] == year){
                tab.push(f)
            }
        })
        return tab
    }

    function getMonthlyKm(month){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[1] == (month+1) && date[0] == year){
                if(e.distance != ""){
                    tab.push(parseInt(e.distance))
                }
            }
        })
        return lodash.sum(tab)
    }

    function getMonthlyPlein(month){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[1] == (month+1) && date[0] == year){
                tab.push(parseInt(e))
            }
        })
        return tab.length
    }

    function getMonthlyDepense(month){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[1] == (month+1) && date[0] == year){
                    tab.push(parseFloat(e.prix))
            }
        })
        return lodash.sum(tab)
    }

    function getYearlyKm(year){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[0] == year){
                if(e.distance != ""){
                    tab.push(parseInt(e.distance))
                }
            }
        })
        return lodash.sum(tab)
    }

    function getYearlyPlein(year){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[0] == year){
                tab.push(parseFloat(e))
            }
        })
        return tab.length
    }

    function getYearlyDepense(year){
        let tab = []
        lodash.forEach(essences,(e)=>{
            let date = e.dateDebut.split("-")
            if(date[0] == year){
                tab.push(parseFloat(e.prix))
            }
        })
        return lodash.sum(tab)
    }

    function getYearlyMovie(year){
        let tab = []
        lodash.forEach(films,(f)=>{
            let date = f.date.split("-")
            if(date[0] == year){
                tab.push(f)
            }
        })
        return tab
    }

    function getAllDepense(){
        let tab = []
        lodash.forEach(essences,(e)=>{
            tab.push(parseFloat(e.prix))
        })
        return lodash.sum(tab)
    }

    function getAllKm(){
        let tab = []
        lodash.forEach(essences,(e)=>{
            if(e.distance != ""){
                tab.push(parseFloat(e.distance))
            }
        })
        return lodash.sum(tab)
    }

    function getAllMovies(){
        return films.length
    }

    function getAllPlein(){
        return essences.length
    }

    

    return(
        <>  
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">

            <div className="my-3">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Ce mois-ci
                </h3>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Nombre de pleins fait
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getMonthlyPlein(month) }}
                                    config={{duration:500}}
                                >
                                    {props =>(
                                       <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                         {props.number.toFixed()}
                                        </dd>
                                    )}

                                </Spring>
                                
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Total dépensé
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getMonthlyDepense(month) }}
                                    config={{duration:500}}
                                >
                                    {props =>(
                                        <dd className="flex mt-1 justify-center text-3xl leading-9 font-semibold text-gray-900">
                                        {props.number.toFixed()} €
                                        
                                    </dd>
                                    )}

                                </Spring>
                                
                                
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Kms effectué
                                </dt>
                                 <Spring
                                    from={{number:0}}
                                    to={{number:getMonthlyKm(month) }}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()} kms
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                   Nombre de film vu
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getMonthlyMovie(month).length }}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()}
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="my-3">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Cette année
                </h3>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Nombre de pleins fait
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getYearlyPlein(year) }}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()}
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Total dépensé
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getYearlyDepense(year) }}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()} €
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Kms effectué
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getYearlyKm(year) }}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()} kms
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                   Nombre de film vu
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getYearlyMovie(year).length}}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()}
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-3">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Depuis toujours
                </h3>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Nombre de pleins fait
                                </dt>

                                <Spring
                                    from={{number:0}}
                                    to={{number:getAllPlein() }}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()}
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Total dépensé
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getAllDepense() }}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()} €
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                    Kms effectué
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getAllKm() }}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()} kms
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dl>
                                <dt className="text-sm text-center leading-5 font-medium text-gray-500 truncate">
                                   Nombre de film vu
                                </dt>
                                <Spring
                                    from={{number:0}}
                                    to={{number:getAllMovies()}}
                                    config={{duration:500}}
                                >
                                    {props => (
                                        <dd className="mt-1 text-center text-3xl leading-9 font-semibold text-gray-900">
                                            {props.number.toFixed()}
                                        </dd>
                                    )}
                                </Spring> 
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            


        </div>
            
        </>
    )
}