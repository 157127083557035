import React from 'react';
import '../App.css';
import {Router,Route,Switch,Redirect} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import PrivateRoute from './SpecialRoutes/PrivateRoute'
import AdminRoute from './SpecialRoutes/AdminRoute'

import Signup from './Auth/Signup'
import LoginPage from './Auth/Login'

import Header from './Header'

import Home from './Pages/Home'
import Dashboard from './Pages/Dashboard'
import Parametres from './Pages/Parametres'
import NotFound from './Pages/NotFound'
import Profil from './Pages/Profil';
import Films from './Pages/Films/List';
import Essence from './Pages/Essences/Essence';
import ValentinesDay from './Pages/ValentinesDay'


const customHistory = createBrowserHistory()

//Routing de l'application web
const Routing =()=> (
        <Router history={customHistory} >
            <Header />
            <Switch>
                <Route exact path="/index.html"><Redirect to="/" /></Route>
                <Route path="/login" render={(props) => (<LoginPage {...props} />)} />
                <Route path="/signup" render={(props) => (<Signup {...props} />)} />
                <Route path="/valentinesDay" render={(props) => (<ValentinesDay {...props} />)} />
                
                <PrivateRoute component={Home} path="/" exact />
                <PrivateRoute component={Dashboard} path="/dashboard" exact />
                <PrivateRoute component={Profil} path="/profil" exact />
                <PrivateRoute component={Films} path="/films" exact />
                <PrivateRoute component={Essence} path="/essence" exact />
                
                <AdminRoute component={Parametres} path="/parametres" exact />

                <PrivateRoute component={NotFound} path='*' error={'test'}/>

            </Switch>
        </Router>
)
export default Routing
