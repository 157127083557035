import React from 'react';

export default function Foxx () {

    return(

            <svg viewBox="0 0 1417.5 1417.5">
                                <g id="Contour" >
                                <path stroke="#000000" strokeWidth="1.5" d="M1053.83+471.528C1053.83+471.528+1028.47+371.322+1195.78+212.208C1195.78+212.208+1298.17+328.249+1168.48+525.632C1168.48+525.632+1227.05+566.511+1231.18+576.75C1235.31+586.99+1440.38+328.149+1205.14+22.184C1191.95+38.9949+1208.1+22.4543+887.116+410.597C950.2+417.196+1038.36+441.968+1053.83+471.528Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#000000" strokeWidth="1.5" d="M330.381+471.528C330.381+471.528+355.732+371.322+188.422+212.208C188.422+212.208+86.0329+328.249+215.726+525.632C215.726+525.632+157.16+566.511+153.03+576.75C148.9+586.99-56.1709+328.149+179.067+22.184C192.257+38.9949+176.104+22.4543+497.09+410.597C434.006+417.196+345.846+441.968+330.381+471.528Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#000000" strokeWidth="0.29" d="M1067.93+915.607C1067.93+915.607+1079.15+1089.12+883.111+1082.89C934.523+900.613+1067.93+915.607+1067.93+915.607Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#000000" strokeWidth="0.29" d="M469.253+1092.86C469.253+1092.86+489.18+955.834+306.225+906.709C297.255+1015.22+349.12+1079.33+469.253+1092.86Z" fill="#0d0000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#ff7800" strokeWidth="0.17" d="M932.242+673.443C919.605+762.982+917.741+769.692+913.568+825.015C968.981+750.333+1032.8+703.027+1032.8+703.027C1032.8+703.027+981.115+681.714+932.242+673.443Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#ffffff" strokeOpacity="0" strokeWidth="0.1" d="M636.189+736.589C679.695+685.121+767.587+573.398+861.406+475.758C956.685+501.773+1053.98+547.053+1153.49+627.604C1100.19+654.241+1076.9+669.527+1032.85+703.134C1002.9+693.025+970.548+681.387+935.456+674.192C933.696+673.831+933.805+674+932.122+673.688C778.118+721.83+647.971+735.118+636.189+736.589Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#ffffff" strokeOpacity="0" strokeWidth="0.35" d="M718.502+992.578C718.502+992.578+747.377+1007.9+814.929+1015.03C837.813+954.258+859.382+902.032+913.545+825.117C926.008+723.56+913.427+825.76+932.341+673.303C740.474+956.742+718.502+992.578+718.502+992.578Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#ffffff" strokeOpacity="0" strokeWidth="0.42" d="M718.545+992.538C719.299+997.88+645.141+767.88+636.265+736.468C728.429+726.503+881.331+687.725+933.507+673.007C793.833+884.761+747.882+947.741+718.545+992.538Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path d="M589.157+1239.8C635.691+1206.02+729.563+1194.92+776.066+1199.45C772.861+1239.82+772.146+1277.13+774.143+1304.29C774.24+1305.6+774.258+1306.76+774.368+1308.03C758.145+1307.8+614.014+1306.7+593.735+1307.37C593.363+1288.34+591.738+1270.68+589.157+1239.8Z" opacity="1" fill="#c53030"/>
                                <path stroke="#ffffff" strokeOpacity="0" strokeWidth="0.42" d="M475.422+712.098C540.503+721.475+591.255+730.39+636.24+736.642C561.421+828.465+560.546+826.127+520.191+873.184C510.267+884.756+502.91+891.951+498.16+898.773C490.329+839.02+475.422+712.098+475.422+712.098Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#ffffff" strokeOpacity="0" strokeWidth="0.42" d="M861.398+475.754C793.066+546.848+703.418+658.765+636.547+736.844C474.89+712.73+635.504+736.658+475.109+712.42C472.077+622.288+471.135+550.439+463.786+484.697C528.515+466.444+657.421+424.001+861.398+475.754Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#fb0101" strokeOpacity="0" strokeWidth="0.42" d="M589.049+1239.73C585.007+1203.65+582.162+1176.26+578.897+1162.34C631.717+1093.85+697.278+1016.96+718.578+992.574C768.682+1005.64+720.03+994.268+815.072+1015.07C795.695+1077.69+788.837+1099.9+776.417+1199.59C663.626+1197.3+609.111+1226.07+589.049+1239.73Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#f9f9f9" strokeOpacity="0" strokeWidth="0.42" d="M578.99+1162.34C636.43+1088.54+688.937+1027.02+718.625+992.609C680.255+977.466+695.727+980.316+498.111+898.445C532.278+965.722+554.796+1027.58+578.99+1162.34Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#ffffff" strokeOpacity="0" strokeWidth="0.42" d="M361.354+726.549C423.346+718.229+434.966+716.986+475.431+712.057C487.625+771.842+495.053+868.9+498.198+899.195C444.618+800.695+417.983+780.163+361.354+726.549Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#ffffff" strokeOpacity="0" strokeWidth="0.42" d="M202.634+622.102C241.976+583.756+374.819+509.047+463.785+484.723C469.012+521.152+479.585+585.293+475.262+712.092C435.44+723.647+437.985+724.356+361.566+726.333C263.566+652.171+203.807+639.438+202.634+622.102Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#ffffff" strokeOpacity="0" strokeWidth="0.42" d="M718.762+993.155L636.547+735.998L496.497+898.096" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#000000" strokeWidth="2.36" d="M686.967+1399.91C689.198+1398.98+775.753+1307.17+775.807+1308.01C775.86+1308.84+730.723+838.754+1154.52+627.464C941.865+451.447+722.172+451.063+679.782+450.935C679.782+450.935+450.634+434.212+204.168+622.051C201.132+664.327+565.922+688.724+595.379+1307.3C595.379+1307.3+684.735+1400.84+686.967+1399.91Z" fill="#c53030" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                <path stroke="#000000" strokeWidth="0.29" d="M685.228+1400.29L774.273+1307.98L593.854+1307.34L685.228+1400.29Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
                                </g>
                            </svg>
                            
                            )
                        }