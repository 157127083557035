import React,{useState} from 'react';
import moment from 'moment'
import Disponibility from './Disponibility'
import ReactTooltip from 'react-tooltip'
import {formatDate,dayOneOrPreview} from '../../../functions/utils'

export default function Film ({titre,date,vu,id,editMovie}){

    const [today,setToday] = useState( moment().format("YYYY-MM-DD"))
    const [jours,setJours] = useState(countdownDate(date))


    function countdownDate(date){
        if(date == ""){
            return ""
        }
        var a = moment(today, 'YYYY-MM-DD');
        var b = moment(date, 'YYYY-MM-DD');
        var days = b.diff(a, 'days');
        return days
    }


    return (
        
        <li className={(dayOneOrPreview(date,vu) == "dayone" ? " border border-teal-400 " : dayOneOrPreview(date,vu) == "preview" ? " border border-indigo-400 " : "") +" col-span-1 bg-white rounded-lg shadow "}>
            <div  className="flex justify-end text-sm mr-3 mt-2 ">
                <svg onClick={()=>editMovie(id)} className="h-6 w-6 text-gray-400 cursor-pointer"  viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg>
            </div>
            <div className="w-5/6 flex items-center px-6 pb-2 -mt-3 ">
                <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3 w-4/5">
                        <h3 data-tip={titre} className="text-gray-900 text-sm leading-5 font-medium truncate uppercase">{titre}</h3>
                        <Disponibility days={jours} vu={vu}/>
                    </div>
                    <p className={(vu == "" ? " invisible " : "") + "text-left text-gray-500 text-sm leading-5 truncate"}> {vu ? "Vu le "+ formatDate(vu) :"empty"} </p>
                </div>
            </div>
            <div className="border-t border-gray-200">
                <div className="-mt-px flex">
                    <div className="w-0 flex-1 flex border-r border-gray-200">
                        <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg transition ease-in-out duration-150">
                            <svg  viewBox="0 0 20 20" fill="#cbd5e0" className="calendar w-6 h-6">
                                <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path>
                            </svg>
                            <span className="ml-3">{formatDate(date)}</span>
                        </div>
                    </div>
                    <div className="-ml-px w-0 flex-1 flex">
                        <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg transition ease-in-out duration-150">
                            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="hour-glass" fill="#cbd5e0">
                                        <path d="M17,18 C17,15.207604 15.3649473,12.7970951 13,11.6736312 L13,8.32636884 C15.3649473,7.2029049 17,4.79239596 17,2 L19,2 L19,0 L1,0 L1,2 L3,2 C3,4.79239596 4.63505267,7.2029049 7,8.32636884 L7,11.6736312 C4.63505267,12.7970951 3,15.207604 3,18 L1,18 L1,20 L19,20 L19,18 L17,18 Z M15,2 C15,4.41895791 13.2822403,6.43671155 11,6.89998188 L11,7.96455557 L11,10 L9,10 L9,7.96455557 L9,6.89998188 C6.71775968,6.43671155 5,4.41895791 5,2 L15,2 Z">  
                                        </path>
                                    </g>
                                </g>
                            </svg>
                            <span className="ml-3">
                                {jours === 0 ? "Aujourd'hui" : jours < 0 ? "Sortie" : jours + " jours"}
                            </span>
                        </div>
                    </div>

                    
                </div>
            </div>
            <ReactTooltip/>
        </li>
    )
}