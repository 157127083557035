import React,{useState, useEffect} from 'react';
import moment from 'moment'
import {months} from '../../datas/datas'
import lodash from 'lodash'
import CardEssence from './CardEssence'
import NewCard from './NewCard';
import CreateCard from './CreateCard';
import { getUserIDAuth,getToken } from '../../../functions/Authentication';
import { trierDateDecroissant,isValidDate,trierDateCroissant,scrollTop,formatDate,showNextMonth,showPreviousMonth,getMonthly} from '../../../functions/utils'

export default function Essence (props){

    const [month,setMonth] = useState(moment().month())
    const [year,setYears] = useState(moment().year())
    const [essences,setEssences] = useState([])
    const [created,setCreated] = useState(false)
    const [edited,setEdited] = useState(false)
    const [error,setError] = useState(false)

    const [editCard,setEditCard] = useState(
        {
            prix:"",
            distance:"",
            dateDebut:"",
            dateFin:""
        }
    )


    useEffect(() => {
        fetchEssences()
    }, []);


    function fetchEssences(){
        fetch(process.env.REACT_APP_API_URL+"/essence/getAll",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                authorization:getToken()
            },
            body:JSON.stringify({
                user_id:getUserIDAuth()
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.err){
                setError(json.err)
            }else{
                console.log(json)
                setEssences(json.essences)
            }
            
        }).catch((error)=>{
            console.log(error)
        })
    }

    function getMonthly(month,year){
        let tab = []
        lodash.forEach(essences,(d)=>{
            let date = d.dateDebut.split("-")
            if(date[1] == (month+1) && date[0] == year){
                tab.push(d)
            }
        })
        return tab
    }
      //Pässe au mois précédent
    function previous(){
        if(month > 0 ){
            setMonth(month - 1)
        }
    }

    //Passe au mois suivant
    function next(){
        if(month < 11 ){
            setMonth(month + 1)
        }
    }

    function onChangeCard(e){
        setEditCard({...editCard,[e.target.id]:e.target.value}) 
        
    }

    function addCard(){
        if(editCard.prix == "" || editCard.dateDebut == "" || isValidDate(editCard.dateDebut)==false){
            setError("Des champs obligatoire n'ont pas été remplis.")
        }else{
            setError("")
            if(editCard.distance == ""){
                editCard.distance=0
            }
        
            fetch(process.env.REACT_APP_API_URL+"/essence/add",{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    authorization:getToken()
                },
                body:JSON.stringify({editCard,user_id:getUserIDAuth()})
            })
            .then((response)=>{
                return response.json()
            })
            .then((json)=>{
                setEditCard( {
                    prix:"",
                    distance:"",
                    dateDebut:"",
                    dateFin:""
                })
                setCreated(false)
                fetchEssences()
                
            }).catch((error)=>{
                console.log(error)
            })
        }
    }

    function openCreateCard(){
        setCreated(true)
    }

    function openEditEssence(id){
        let film
        lodash.forEach(essences,(e)=>{
            if ( e.id == id){
                film = e
            }
        })
        setEdited(true)
        setEditCard(film)
    }

    function goBack(){
        setCreated(false)
        setEdited(false)
        setEditCard( {
            prix:"",
            distance:"",
            dateDebut:"",
            dateFin:""
        })
    }

    function updateCard(){

        if(editCard.prix == "" || editCard.dateDebut == "" || isValidDate(editCard.dateDebut)==false || (editCard.dateFin && isValidDate(editCard.dateFin) == false) ){
            setError("Des champs obligatoire n'ont pas été remplis.")
        }else{

            setError("")
            fetch(process.env.REACT_APP_API_URL+"/essence/update/"+editCard.id,{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    authorization:getToken()
                },
                body:JSON.stringify(editCard)
            })
            .then((response)=>{
                return response.json()
            })
            .then((json)=>{
                if(!json.err){
                    setEditCard( {
                        prix:"",
                        distance:"",
                        dateDebut:"",
                        dateFin:""
                    })
                    setEdited(false)
                    fetchEssences()
                }else{
                    setError(json.msg)
                }
            }).catch((error)=>{
                console.log(error)
                setError(error)
            })
        }
    }

    function deleteCard(){
        fetch(process.env.REACT_APP_API_URL+"/essence/delete/"+editCard.id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                authorization:getToken()
              },
            body:JSON.stringify(editCard)
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(!json.err){
                setEditCard( {
                    prix:"",
                    distance:"",
                    dateDebut:"",
                    dateFin:""
                })
                setEdited(false)
                fetchEssences()
            }else{
                setError(json.msg)
            }
        }).catch((error)=>{
            console.log(error)
            setError(error)
        })
    }


    return (
        <>
        {created ? 
            <div className="fixed z-10 inset-0 overflow-y-auto">
                
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>


                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    
                    {error ? 
                        <div class="rounded-md bg-red-200 p-4 mb-3">
                            <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>
                            </div>
                                <div class="ml-3">
                                    <h3 class="text-sm leading-5 font-medium text-red-800">
                                        {error}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    : null}

                        <CreateCard onChange={onChangeCard} card={editCard}  />

                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                            <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                            <button onClick={()=>addCard()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-700 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Ajouter la carte
                            </button>
                            </span>
                            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                            <button  onClick={()=>goBack()} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Retour
                            </button>
                            </span>
                        </div>
                    </div>
                </div>
                </div>
            : null}
            {edited ? 
            <div className="fixed z-10 inset-0 overflow-y-auto">
                
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>


                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    
                    {error ? 
                        <div class="rounded-md bg-red-200 p-4 mb-3">
                            <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                </svg>
                            </div>
                                <div class="ml-3">
                                    <h3 class="text-sm leading-5 font-medium text-red-800">
                                        {error}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    : null}

                        <CreateCard onChange={onChangeCard} card={editCard}  />

                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
                        
                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
                            <button onClick={()=>updateCard()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-700 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                            Modifier la carte
                            </button>
                        </span>

                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 ">
                            <button onClick={()=>deleteCard()} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-red-700 text-base leading-6 font-medium text-white shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Supprimer
                            </button>
                        </span>

                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 ">
                            <button onClick={()=>goBack()} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Retour
                            </button>
                        </span> 
                    </div>
                    </div>
                </div>
                </div>
            : null}

            

            
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">
            <div className="flex flex-row items-center justify-center mb-3">
                <div className="mr-1">
                    Année 
                </div>
                <div>
                    <select onChange={(e)=>setYears(e.target.value)} className="rounded-md bg-gray-100" name="pets" id="pet-select" value={year}>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>

                    </select> 
                </div>
            </div>

        <nav  className="mb-4 px-4 flex items-center justify-between sm:px-0">
                <div className={(month == 0 && "invisible ") + "w-0 flex-1 flex lg:hidden"}>
                    <div onClick={previous} className="-mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 cursor-pointer transition ease-in-out duration-150">
                        <svg className="mr-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        {showPreviousMonth(month)}
                    </div>
                </div>
            <div className="hidden lg:flex lg:justify-center lg:w-full">
                {months.map((m,i)=>{
                    if(month == m.index){
                        return (
                            <div key={i} onClick={()=>setMonth(m.index)} className="cursor-pointer -mt-px border-t-2 border-primary pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium text-primary  transition ease-in-out duration-150">
                                {m.libelle}
                            </div>
                        )
                    }else{
                        return(
                            <div key={i} onClick={()=>setMonth(m.index)} className="cursor-pointer -mt-px border-t-2 border-transparent pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 transition ease-in-out duration-150">
                                {m.libelle}
                            </div>
                        )
                    }
                })}
            </div>
            <div className="flex justify-center lg:hidden -mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium text-primary cursor-pointer transition ease-in-out duration-150">
                {months.map((m,i)=>{
                    if(month == m.index){
                        return (
                            <div key={i}>{m.libelle}</div>
                        )
                    }
                })}
            </div>
            <div className={(month == 11 && "invisible ")+"w-0 flex-1 flex lg:hidden justify-end"}>
                <div onClick={next} className="-mt-px border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 cursor-pointer transition ease-in-out duration-150">
                    {showNextMonth(month)}
                    <svg className="ml-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </div>
            </div>
        </nav>


            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                {trierDateCroissant(getMonthly(month,year)).map((d,i)=>{
                    return (
                        <CardEssence key={i} id={d.id} prix={d.prix} distance={d.distance} dateDebut={d.dateDebut} dateFin={d.dateFin} editEssence={openEditEssence}/>
                    )
                })}
              
                    <NewCard openCreateCard={openCreateCard} />

            </div>
            
                <div id="buttonToTop" onClick={scrollTop} className="bg-gray-400 p-3 rounded-full fixed bottom-0 right-0 mb-6 mr-6 md:mb-8 md:mr-8 lg:mb-12 lg:mr-12 cursor-pointer hover:bg-gray-300">
                    <svg className="h-6 w-6 text-gray-700"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <polyline points="17 11 12 6 7 11" />  <polyline points="17 18 12 13 7 18" /></svg>
                </div>
        </div>
        </>
    )
}


