import React from 'react';


export default function CreateCard({card,onChange}){

    return (
        <>

            <div className="flex flew-row">
                <div className="w-full">
                    <label htmlFor="prix" className="block text-sm font-medium leading-5 text-gray-700">
                        Prix
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                        <input autoComplete="off" onChange={(e)=>onChange(e)}  type="number" id="prix" placeholder="Prix en €" value={card.prix} className=" w-full my-2 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" />
                    </div>
                </div>
                <div  className="w-full">
                    <label htmlFor="email" className="ml-2 block text-sm font-medium leading-5 text-gray-700">
                        Distance
                    </label>
                    <div className="mt-1 ml-2">
                        <input autoComplete="off" onChange={(e)=>onChange(e)}  type="number" id="distance" placeholder="Distance en Km" value={card.distance} className=" w-full my-2 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" />
                    </div>
                </div>        
            </div>
            <div className="flex flew-row">
                <div className="w-full">
                    <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                        Date du plein
                    </label>
                    <div className="mt-1 ">
                        <input autoComplete="off" onChange={(e)=>onChange(e)} type="text" placeholder="YYYY-MM-DD " id="dateDebut" value={card.dateDebut} className=" w-full my-2 mr-2 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" />
                    </div>
                </div>
                <div  className="w-full">
                    <label htmlFor="email" className="ml-2 block text-sm font-medium leading-5 text-gray-700">
                        Date nouveau plein
                    </label>
                    <div className="mt-1 ml-2 ">
                        <input autoComplete="off" onChange={(e)=>onChange(e)} type="text" placeholder="YYYY-MM-DD " id="dateFin" value={card.dateFin} className=" w-full my-2 bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block appearance-none leading-normal" />
                    </div>
                </div>        
            </div>
                    
                   
       </>
    )
}