import React, { useEffect, useState } from 'react';
import { getUserIDAuth,getToken } from '../../functions/Authentication';

export default function Profil (props) {

    useEffect(() => {
        fetchUser()
    }, []);

    const [user,setUser] = useState({})
    const [error,setError] = useState('')

    function fetchUser(){
        fetch(process.env.REACT_APP_API_URL+"/user/get",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                authorization:getToken()
            },
            body:JSON.stringify({
                user_id:getUserIDAuth()
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.err){
                setError(json.err)
            }else{
                if(!json.user){
                    setError("Aucune utilisateur n'a été trouvé.")
                    setUser(json.user)
                }else{
                    console.log(json)
                    setUser(json.user)
                }
            }
            
        }).catch((error)=>{
            console.log(error)
        })
    }

    return ( 
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Mon profil
                    </h3>
                </div>
                <div className="px-4 py-5 sm:p-0">
                    <dl>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Prénom
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                Valentin
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Nom
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                Backend Developer
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Age
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                               22 ans
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Adresse mail
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                               {user.email}
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Ville
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                Bordeaux
                            </dd>
                        </div>
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Nom d'utilisateur
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {user.login}
                            </dd>
                        </div>
                        
                    </dl>
                </div>
            </div>
        </div>
       

    )
}