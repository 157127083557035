import React from 'react'


export default function Parametres (props) {

    return(
        <>  
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">
            <div className="alert alert-success" role="alert">
                Parametres
            </div> 
        </div>
            
        </>
    )
}