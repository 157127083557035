import React from 'react';

export default function Disponibility ({days,vu}){

    if(days == "" || vu == ""){
        return ''
    }

    if(vu != "" || vu != null){
        return (
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-900 text-xs leading-4 font-medium bg-blue-400 rounded-full p-1">Vu !</span>  
        )
    }else{
        if(days === 0){
            return (
                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-900 text-xs leading-4 font-medium bg-red-400 rounded-full p-1">Nouveau !</span>  
            )
        }else if ( days > 0 && days <= 15){
            return (
                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-900 text-xs leading-4 font-medium bg-orange-400 rounded-full p-1">Bientôt !</span>  
            )
        }else {
            return (
                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-900 text-xs leading-4 font-medium bg-green-400 rounded-full p-1">Disponible !</span>  
            )
        }
    }

    

}