import React, { useState,useEffect } from 'react'
import Lottie from 'react-lottie'
import moment from 'moment'
import LoveThree from '../../lotties/LoveThree.json'
import LoveBird from '../../lotties/LoveBird.json'
import happyValentinesDay from '../../lotties/happyValentinesDay.json'
import LoveLetter from '../../lotties/LoveLetter.json'
import CoutDown from '../../lotties/Coutdown.json'
import Pict1 from '../../images/1.jpeg'
import Pict2 from '../../images/2.jpg'
import Pict3 from '../../images/3.jpg'
import Pict4 from '../../images/4.jpg'
import Pict5 from '../../images/5.jpg'
import Pict6 from '../../images/6.jpeg'
import Pict7 from '../../images/7.jpg'
import Pict8 from '../../images/8.jpg'
import Pict9 from '../../images/9.jpeg'

export default function ValentineDays (props) {

    const [today,setToday] = useState( moment().format("YYYY-MM-DD"))
    const [step,setStep] = useState(0)
    const [coutdown,setCoutdown] = useState(countdownDate("2020-10-06"))
    const [timer,setTimer] = useState(0)
    const [timerPict,setTimerPict] = useState(0)

    useEffect(() => {
        if(step >= 4 && step < 5){
            setTimeout(() => {
                setTimer(timer+1)
            }, 1500);
        }
    }, [step,timer]);

    useEffect(() => {
        if(step >= 6 && step < 7){
            setTimeout(() => {
                setTimerPict(timerPict+1)
            }, 1800);
        }
    }, [step,timerPict]);

    
    function countdownDate(date){
        var a = moment(today, 'YYYY-MM-DD');
        var b = moment(date, 'YYYY-MM-DD');
        var days = a.diff(b, 'days');
        return days
    }

    const optHeart = {
        loop: true,
        autoplay: true,
        animationData: LoveBird,
    };

    const optCountDown = {
        loop: false,
        autoplay: true,
        animationData: CoutDown,
    };

    const optThree = {
        loop: false,
        autoplay: true,
        animationData: LoveThree,
    };

    const optLetter = {
        loop: false,
        autoplay: false,
        animationData: LoveLetter
    };

    const optHappy = {
        loop: false,
        autoplay: true,
        animationData: happyValentinesDay,
    };

    const nextStep = (step) =>{
        setTimeout(() => {
            setStep(step+1)
        }, 500);
        
    }
  
    return(
        <div className="lottie h-full flex flex-col items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
            <div className=" w-full">

        
                {step == 0 && 
                <>
                    <div class="flex items-center justify-center mt-12 p-2 bg-red-200 rounded-md ">
                        Je crois que tu as un petit message..
                    </div>
                    <div class="flex items-center justify-center mt-12 text-xl underline">
                       Clique dessus !
                    </div>
                    
                    <Lottie
                        options={optLetter}
                        isClickToPauseDisabled={false}
                        height={500}
                        width={window.innerWidth-100}
                        eventListeners={
                            [
                                {
                                    eventName: 'complete',
                                    callback: () =>nextStep(step),
                                }
                            ]
                        }
                    />
                </>
                }

            {step == 1 && 
                <>
                    <div class="flex items-center justify-center mt-8 text-xl underline">
                        Chère Baby,
                    </div>
                    <div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                        Aujourd'hui c'est la Saint Valentin, la fête des amoureux. On
                        se connaît maintenant depuis maintenant {coutdown} jours (ça fait {coutdown*24} heures, ou bien {(coutdown*24)*60} minutes,
                         ou bien {((coutdown*24)*60)*60} secondes). Ehhhh oui,
                        ça passe vite n'empêche ! J'aurais jamais cru rencontrer une personne comme 
                        toi sur un putain de site de rencontre en vrai. Fruitz mdrrr !
                        Puis, ça a été tellement vite ! On s'est vu, le lendemain on se revoyait, et on s'est revu encore le lendemain. On s'arrêtait déjà plus.
                        Chaque instant passé avec toi c'est un truc de ouf. Tu me fais tellement rire, on s'entend tellement bien, j'sais pas, y'a un feeling de taré.
                        Regarde, on a passé nos soirées dans la voiture pendant le confinement, et JAMAIS on s'est ennuyer..
                        Depuis ce jour bahhh, voilà où on en est. Un petit couple de porkitass mdrrr, qui peut plus se passer l'un de l'autre.
                    </div>

                    <div class="flex flex-row items-center justify-center mt-6">
                        <button onClick={()=>nextStep(step)} className="bg-pink-300 p-2 rounded-md">
                            Suivant
                        </button>
                    </div>
                </>
            }

                {step == 2 && 
                    <>
                        <Lottie
                            options={optHeart}
                            isClickToPauseDisabled={true}
                            height={300}
                            width={window.innerWidth-100}
                            eventListeners={
                                [
                                    {
                                        eventName: 'complete',
                                        callback: () =>nextStep(step),
                                    }
                                ]
                            }
                        />
                            <div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                               TOU DOUM, TOU DOUM, TOU DOUM
                            </div>
                            <div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                                Ca, c'est mon petit coeur qui bat pour toi. ( bon j'avoue il est un peut gros là, mais je parle pas de ma bi** pour une fois, oupsss )
                            </div>

                        <div class="flex flex-row items-center justify-center mt-6">
                            <button onClick={()=>nextStep(step)} className="bg-pink-300 p-2 rounded-md">
                                Suivant
                            </button>
                        </div>
                        </>
                }

                {step == 3 && 
                    <>
                        <Lottie
                            options={optThree}
                            isClickToPauseDisabled={true}
                            height={300}
                            width={window.innerWidth-100}
                        />
                        <div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                            Et ça, c'est un jolie petit arbre ( tu restes quand même beaucouuuup plus jolie hein ! ) qui représente notre amour qui grandit de jour en jour.
                            Et j'espère qui va pas pourrir par contre !
                            Il a même plein de petites roses, parce que les roses c'est beauuu et ça sent bon comme toi.
                        </div>

                        <div class="flex flex-row items-center justify-center mt-6">
                            <button onClick={()=>nextStep(step)} className="bg-pink-300 p-2 rounded-md">
                                Suivant
                            </button>
                        </div>
                        </>
                }

                {step == 4 && 
                    <>
                        <div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                            J'espère sincèrement que l'on va vivre notre petit amour aussi longtemps que possible.
                            Je nous vois déjà dans notre petit appartement alala, soirée télé, crêpes, Malteser, Netflix&Chill.
                            J'ai aucunement envie de te perdre, je tiens vraiment trop à toi pour ça.
                            Je sais bien que je suis pas toujours agréable dans ma façon de parler mdrr, mais je suis désolé, c'est vraiment pas fait exprès
                            et je m'en rend pas toujours compte. En tout cas, ce qui est sûr, c'est que je t'aime vraiment très fort.
                        </div>

                        {timer >= 1 && <div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                            VRAIMENT TRES FORT
                        </div>}
                        {timer >= 2 && <div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                            VRAIMENT TRES TRES FORT
                        </div>}
                        {timer >= 3 && <div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                            VRAIMENT TRES TRES TRES FORT
                        </div>}

                        {timer >= 4 &&<div class="flex items-center justify-center mt-8 bg-red-200 p-2 rounded-md text-center">
                            Bon j'arrête mdrrrr !
                        </div>}

                        {timer >= 5 && <div class="flex flex-row items-center justify-center mt-6">
                            <button onClick={()=>nextStep(step)} className="bg-pink-300 p-2 rounded-md">
                                Prépare toi ..
                            </button>
                        </div>}
                        
                    </>
                }

                {step == 5 && 
                    <>
                        <Lottie
                            options={optCountDown}
                            isClickToPauseDisabled={true}
                            height={500}
                            width={window.innerWidth-100}
                            eventListeners={
                                [
                                    {
                                        eventName: 'complete',
                                        callback: () =>nextStep(step),
                                    }
                                ]
                            }
                        />
                        
                        </>
                }

                {step == 6 && 
                    <>
                        {timerPict == 0 &&
                        <div className="flex justify-center items-center mt-64 text-5xl">C'est partie</div>
                        }
                        {timerPict == 1 &&
                            <img src={Pict1}class="" />
                        }
                        {timerPict == 2 &&<div >
                            <img src={Pict2} class="fixed mt-0"/>
                        </div>}
                        {timerPict == 3 &&<div >
                            <img src={Pict3} class="fixed mt-0"/>
                        </div>}
                        {timerPict == 4 &&<div >
                            <img src={Pict4} class="fixed mt-0"/>
                        </div>}
                        {timerPict == 5 &&<div >
                            <img src={Pict5} class="fixed mt-0"/>
                        </div>}
                        {timerPict == 6 &&<div >
                            <img src={Pict6} class="fixed mt-0"/>
                        </div>}
                        {timerPict == 7 &&<div >
                            <img src={Pict7} class="fixed mt-0"/>
                        </div>}
                        {timerPict == 8 &&<div >
                            <img src={Pict8} class="fixed mt-0"/>
                        </div>}
                        {timerPict == 9 &&<div >
                            <img src={Pict9} class="fixed mt-0"/>
                        </div>}

                        {timerPict >= 10 && <div class="flex flex-row items-center justify-center mt-64">
                            <button onClick={()=>nextStep(step)} className="bg-pink-300 p-2 rounded-md">
                                Suivant
                            </button>
                        </div>}
                        
                    </>
                }

                {step == 7 && 
                    <>
                        <Lottie
                            options={optHappy}
                            isClickToPauseDisabled={true}
                            height={500}
                            width={window.innerWidth-100}
                        />
                        

                        <div class="z-10 flex flex-row items-center justify-center mt-6">
                            <button onClick={()=>nextStep(-1)} className="bg-pink-300 p-2 rounded-md">
                                Revoir
                            </button>
                        </div>
                        
                        </>
                }
                 
            </div>
        </div>
    )
}