import React from 'react';


export default function NewCard ({openCreateCard}){


    return (
        <div onClick={openCreateCard} className="bg-white rounded-lg h-32 flex justify-center items-center border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100">
            <div className="w-full flex-col items-center px-4 pt-4 pb-3 ">
                <div className="flex-1 truncate">
                    <div className="flex justify-center items-center">
                        <h3 className="text-gray-900 text-sm leading-5 font-medium truncate ml-3">Ajouter une carte</h3>
                    </div>
                </div>
                <div className="flex justify-center font-medium text-sm mt-1">
                    
                </div>
            </div>
            
        </div>
    )
}